<template>
  <div class="project-list">
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="maintenance/repair-orders">
      <el-button
        slot="btn"
        :disabled="status==='loading'"
        size="mini"
        style="height: 26px;padding: 0 10px"
        type="primary"
        @click="exportExcelData">
        {{$l("common.search", "导出")}}
      </el-button>
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search label="工单编号">
            <el-input v-model.trim="filter.orderCode" clearable></el-input>
          </vm-search>
          <vm-search label="维修类型">
            <vm-dict-select v-model="filter.typeDesc" type="repairType"></vm-dict-select>
          </vm-search>
          <vm-search label="报修时间开始">
            <el-date-picker
              v-model="filter.createTimeFrom"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </vm-search>
          <vm-search label="报修时间结束">
            <el-date-picker
              v-model="filter.createTimeTo"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </vm-search>
          <vm-search label="品牌">
            <el-input v-model.trim="filter.brandName" clearable></el-input>
          </vm-search>
          <vm-search label="型号">
            <el-input v-model.trim="filter.modelName" clearable></el-input>
          </vm-search>

          <vm-search label="维保项目">
            <el-input v-model.trim="filter.maintenanceProjectName" clearable></el-input>
          </vm-search>
          <vm-search label="注册代码">
            <el-input v-model.trim="filter.regCode" clearable></el-input>
          </vm-search>
          <vm-search label="维保班组">
            <el-input v-model.trim="filter.teamName" clearable></el-input>
          </vm-search>
          <vm-search label="楼盘">
            <el-input v-model.trim="filter.realEstateName" clearable></el-input>
          </vm-search>
          <vm-search label="楼宇">
            <el-input v-model.trim="filter.buildingName" clearable></el-input>
          </vm-search>
          <vm-search label="队长姓名">
            <el-input v-model.trim="filter.teamLeaderName" clearable></el-input>
          </vm-search>
          <vm-search label="队长电话">
            <el-input v-model.trim="filter.teamLeaderPhone" clearable></el-input>
          </vm-search>
          <vm-search label="取消人姓名">
            <el-input v-model.trim="filter.cancelledName" clearable></el-input>
          </vm-search>
          <vm-search label="取消人电话">
            <el-input v-model.trim="filter.cancelledPhone" clearable></el-input>
          </vm-search>
          <vm-search label="取消时间">
            <el-date-picker
              v-model="filter.caTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="handleTime">
            </el-date-picker>
          </vm-search>
        </div>
      </template>
      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="teamName" :label="$l('project.name','维保班组')">
        <el-table-column prop="teamLeaderName" label="队长">
          <template slot-scope="scope">
            <div>
              {{scope.row.teamName}}
            </div>
            <div> {{scope.row.teamLeaderName}}/{{scope.row.teamLeaderPhone}}</div>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column prop="assigneeName" label="工单责任人姓名/电话">
        <template slot-scope="scope">
          {{scope.row.assigneeName}}/{{scope.row.assigneePhone}}
        </template>
      </el-table-column>
      <el-table-column prop="orderCode" label="工单编号"></el-table-column>
      <el-table-column prop="typeDesc" label="维修类型"></el-table-column>
      <el-table-column prop="createTime" label="报修时间"></el-table-column>
      <el-table-column prop="customerCompanyName" label="使用单位"></el-table-column>
      <el-table-column prop="realEstateName" label="楼盘/楼宇">
        <template slot-scope="scope">
          {{scope.row.realEstateName}}/{{scope.row.buildingName}}
        </template>
      </el-table-column>
      <el-table-column prop="elevatorName" label="内部编号"></el-table-column>
      <el-table-column prop="regCode" label="注册代码" align="center" width="180">
        <template slot-scope="scope">
          <el-button type="text" @click="$refs.elevatorDetail.open(scope.row.elevatorId)">{{scope.row.regCode}}</el-button>
        </template>
      </el-table-column>      <el-table-column prop="createTime" label="品牌型号">
        <template slot-scope="scope">
          {{scope.row.brandName}}/{{scope.row.modelName}}
        </template>
      </el-table-column>
      <el-table-column prop="cancelledName" label="取消人姓名/电话">
        <template slot-scope="scope">
          {{scope.row.cancelledName}}/{{scope.row.cancelledPhone}}
        </template>
      </el-table-column>
      <el-table-column prop="cancelledTime" label="取消时间"></el-table-column>
      <el-table-column prop="cancellationRemark" label="取消原因"></el-table-column>
      <el-table-column prop="modelDesc" label="设备型号"></el-table-column>
      <el-table-column prop="faultCode" label="故障代码/故障名称">
        <template slot-scope="scope">
          {{scope.row.faultCode}}/{{scope.row.faultName}}
        </template>
      </el-table-column>
      <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate">
        <template slot-scope="scope">
          <el-button v-if="$auth('已取消详情')" type="primary" @click="$refs.editPage.open(scope.row)">{{$l("common.detail", "详情")}}</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <edit-page ref="editPage" @save-success="getList(-1)"></edit-page>
    <elevator-detail ref="elevatorDetail" @save-success="getList(-1)"></elevator-detail>
  </div>
</template>
<script>
  import EditPage from "./RepairDetailNew.vue";
  import moment from "moment";
  import {export2Excel} from "@/util";
  import elevatorDetail from "@/views/elevator/ElevatorDetail";
  import loginUtil from "@/util/loginUtil";
  export default {
    components: { EditPage,elevatorDetail},
    data() {
      return {
        filter: {
          name: "",
          customerName: "",
          wbManagerName: "",
          orderStatus: "cancelled",
          orderColumnName:"createTime",
          maintenanceType:"as_need",
          createTimeFrom:moment(moment().subtract(1, "months")).format("YYYY-MM-DD"+" 00:00:00"),
          companyId: loginUtil.getCompanyCode(),

        },
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      handleTime() {
        if(this.filter.seTime !=null) {
          this.filter.createTimeFrom = this.filter.seTime[0];
          this.filter.createTimeTo = this.filter.seTime[1];
        }
        if(this.filter.caTime !=null) {
          this.filter.cancelledTimeFrom = this.filter.caTime[0];
          this.filter.cancelledTimeTo = this.filter.caTime[1];
        }
      },
      exportExcelData() {
        const title={
          teamName:"维保班组",
          teamLeaderName:"队长",
          teamLeaderPhone:"联系电话",
          assigneeName:"工单责任人",
          assigneePhone:"电话",
          orderCode:"工单编号",
          typeDesc:"维修类型",
          createTime:"报修时间",
          customerCompanyName:"使用单位",
          realEstateName:"楼盘",
          buildingName:"楼宇",
          elevatorName:"内部编号",
          regCode:"注册代码",
          brandName:"品牌",
          modelName:"型号",
          cancelledName:"取消人姓名",
          cancelledPhone:"取消人电话",
          cancelledTime:"取消时间",
          cancellationRemark:"取消原因",
          modelDesc:"设备型号",
          faultCode:"故障代码",
          faultName:"故障名称",
        };
        this.$http.get("maintenance/repair-orders",{...this.filter,pageSize:300000}).then(res=>{

          export2Excel(title,res.records,`维修工单-已取消-${res.total}条-${moment(new Date()).format("YYYYMMDDhhmmss")}`);
        });
      },
      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
      },
      deleteRow(row) {
        this.$confirm(`确定删除${row.projectName}吗?`, this.$l("common.tip", "提示"), {type: "warning"}).then(() => {
          this.$http.delete(`/maintenance/maintain-plan/${row.id}`).then(() => {
            this.getList(-1);
            this.$message.success(row.projectName + this.$l("common.deleteSuccessTip", "删除成功"));
          });
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
