<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    title="审批"
    top="3vh"
    :modal="false"
    @close="$reset('form')">
    <el-form
      ref="form"
      v-loading="contentLoading"
      :model="model"
      class="form"
      label-width="auto">
      <el-form-item label="备注" prop="approvalRemark" :rules="$rule.notNull">
        <el-input v-model="model.approvalRemark" type="textarea" :rows="2" :placeholder="$l('common.enter','请输入')"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="dialogVisible=false">{{$l("common.cancel", "取消")}}</el-button>
      <el-button @click="dialogVisible=false">{{$l("common.cancel", "拒绝")}}</el-button>
      <el-button :loading="submitLoading" type="primary" @click="handleSubmit">{{$l("common.save", "通过")}}</el-button>
    </span>
    <user-select ref="userSelect" @select="selectUserWb"></user-select>
  </el-dialog>
</template>
<script>
  import UserSelect from "@/views/user/UserSelect";

  export default {
    components: {UserSelect},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        reportRemark:null,
        faultCategoryList:[],
        model: {
          verifierName: null,
          verifiedTime: null,
          verificationRemark:null,
        },
      };
    },
    methods: {
      open(id,reportRemark) {
        this.dialogVisible = true;
        this.model.id = id;
        this.reportRemark = reportRemark;
        this.getFaultCategoryList();
      },
      getFaultCategoryList() {
        this.$http.get(`maintenance/repair-order/${this.model.id}`).then(data => {
          console.log(data,56);
        });

        this.$http.get("repair-report/fault-category/list").then(res=>{
          this.faultCategoryList = res;
        });
      },
      selectUserWb(row) {

        this.$set(this.model,"assigneeId",row.id);
        this.$set(this.model,"assigneeName",row.name);

      },
      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            this.$http
              .post("maintenance/repair-order/"+this.model.id+"/approved", this.model)
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success("审批成功");
              }).catch(() => {
                this.submitLoading = false;
              });
          }
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
.tip {
  margin-top: -8px;
  margin-bottom: 20px;
}
</style>
