<template>
  <div class="project-list">
    <el-tabs v-model="tabActive" type="card" @tab-click="handleClick">
      <el-tab-pane v-if="$auth('维修工单管理待认领')" label="待认领" name="first"><to-be-caim ref="tobecaim"></to-be-caim></el-tab-pane>
      <el-tab-pane v-if="$auth('维修工单管理已认领')" label="已认领" name="second">
        <caimed ref="caimed"></caimed>
      </el-tab-pane>
      <el-tab-pane v-if="$auth('维修工单管理进行中')" label="进行中" name="third">
        <ongoing ref="ongoing"></ongoing>
      </el-tab-pane>
      <el-tab-pane v-if="$auth('维修工单管理进行中')" label="作业完成" name="nine">
        <orderComplete ref="orderComplete"></orderComplete>
      </el-tab-pane>
      <el-tab-pane v-if="$auth('维修工单管理客户确认完成')" label="客户确认完成" name="four">
        <mission-complete ref="missioncomplete"></mission-complete>
      </el-tab-pane>
      <el-tab-pane v-if="$auth('维修工单管理审批完成')" label="审批完成" name="fiv">
        <examine-complete ref="examinecomplete"></examine-complete>
      </el-tab-pane>
      <el-tab-pane v-if="$auth('维修工单管理已取消')" label="已取消" name="sixth">
        <cancel ref="cancel"></cancel>
      </el-tab-pane>
      <el-tab-pane v-if="$auth('维修工单管理误报')" label="误报" name="seven">
        <misinformation ref="Misinformation"></misinformation>
      </el-tab-pane>
      <el-tab-pane v-if="$auth('维修工单管理全部')" label="全部" name="eight">
        <all ref="all"></all>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
  import ToBeCaim from "./ToBeCaim";
  import Caimed from "./Caimed";
  import Ongoing from "./Ongoing";
  import MissionComplete from "./MissionComplete";
  import ExamineComplete from "./ExamineComplete";
  import Cancel from "./Cancel";
  import All from "./All";
  import Misinformation from "@/views/maintenance/repair/reportOrder/Misinformation";
  import orderComplete from "@/views/maintenance/repair/reportOrder/OrderComplete";
  export default {
    components: {ToBeCaim,Caimed,Ongoing,MissionComplete,ExamineComplete,Cancel,All,Misinformation ,orderComplete},
    data() {
      return {
        tabActive: "first",
        filter: {
          name: "",
          customerName: "",
          wbManagerName: "",
          status: "",
          maintenanceType:"as_need",
        },
      };
    },
    mounted() {
      console.log(this.$route.query.orderCode);
      if (this.$route.query.orderCode) {
        this.tabActive = "eight";
        // this.$refs.all.getList(1,this.$route.query.orderCode);
        // this.$refs.all.getStatistics(1);
      }
    },
    methods: {
      handleClick(tab) {
        if (tab.name==="first") {
          this.$refs.tobecaim.getList(1);
        }else if (tab.name==="second") {
          this.$refs.caimed.getList(1);
        }else if (tab.name==="third") {
          this.$refs.ongoing.getList(1);
        }else if (tab.name==="four") {
          this.$refs.missioncomplete.getList(1);
        }else if (tab.name==="fiv") {
          this.$refs.examinecomplete.getList(1);
        }else if (tab.name==="sixth") {
          this.$refs.cancel.getList(1);
        }else if (tab.name==="seven") {
          this.$refs.Misinformation.getList(1);
        }else if (tab.name==="eight") {
          this.$refs.all.getList(1,this.$route.query.orderCode);
        }
        else if (tab.name==="nine") {
          this.$refs.orderComplete.getList(1,this.$route.query.orderCode);
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
