import { render, staticRenderFns } from "./Misinformation.vue?vue&type=template&id=37d67299&scoped=true&"
import script from "./Misinformation.vue?vue&type=script&lang=js&"
export * from "./Misinformation.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37d67299",
  null
  
)

export default component.exports