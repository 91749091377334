<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    title="打印"
    width="920px"
    top="3vh"
    :modal="false"
    @close="$reset('form')">
    <el-button type="primary" @click="printSomething">打印</el-button>

    <div id="print" ref="print">
      <div style="font-size: 40px;font-weight: 900;text-align: center;height: 40px;line-height: 40px">电梯维修单</div>
      <div v-if="print" style="height: 20px"></div>
      <div style="font-size: 20px;font-weight: 900;text-align: right;margin: 0px 20px 0px 0 ">No.{{model.orderCode}}</div>

      <table class="tb" cellspacing="0" cellpadding="0" style="border-collapse:collapse;">
        <tr class="tbtr">
          <td class="tbtd" colspan="1" style="width: 250px;border-right: 1px solid black"> <span class="title">使用单位  </span></td>
          <td :colspan="2" style="width: 500px;"><span class="content">{{model.usingCompanyName}}</span></td>
          <td :colspan="1" style="width: 300px;"></td>
        </tr>
        <tr class="tbtr">
          <td class="tbtd" colspan="1"><span class="title">使用地点 </span></td>
          <td :colspan="2" style="width: 500px;"><span class="content">{{model.detailAddress}}</span></td>
          <td :colspan="1" style="width: 300px;"></td>
        </tr>
        <tr class="tbtr">
          <td class="tbtd" :colspan="1"><span class="title">设备编号</span></td>
          <td :colspan="2"><span class="content">{{model.elevatorName}}</span></td>
          <td :colspan="1"></td>
        </tr>
        <tr class="tbtr">
          <td class="tbtd" colspan="1"><span class="title">制造单位 </span></td>
          <td :colspan="2" style="width: 500px;"><span class="content">{{model.manufacturerName}}</span></td>
          <td :colspan="1" style="width: 300px;"></td>
        </tr>
        <tr class="tbtr">
          <td class="tbtd" colspan="1"><span class="title">电梯型号</span></td>
          <td :colspan="1" style="width: 400px;border-right: 1px solid black"><span class="content">{{model.modelName}}</span></td>

          <td class="tbtd" colspan="1" style="width: 300px"><span class="title">层/站</span></td>
          <td colspan="1" style="width: 400px"><span class="content">{{model.totalFloor}}/{{model.totalStation}}</span></td>
        </tr>
        <tr class="tbtr">
          <td class="tbtd"><span class="title">维修到达时间</span></td>
          <td class="tbtd" colspan="3"><span class="content" style="margin: 0">{{$moment(model.arrivedTime).format("YYYY年MM月DD日 hh:mm:ss")}}</span></td>
        </tr>
        <tr class="tbtr">
          <td class="tbtd"><span class="title">维修结束时间</span> </td>
          <td class="tbtd" colspan="3"><span class="content" style="margin: 0">{{model.completedTime?$moment(model.completedTime).format("YYYY年MM月DD日 hh:mm:ss"):''}}</span></td>
        </tr>
        <tr class="tbtr" style="height: 150px">
          <td
            class="tbtd"
            colspan="4"
            align="left"
            valign="top"
            height="240px">
            <span class="title">故障现象:</span>
            <div class="content">{{model.faultPhenomenon}}</div>
          </td>
        </tr>
        <tr class="tbtr" style="height: 150px">
          <td
            class="tbtd"
            colspan="4"
            align="left"
            valign="top"
            height="240px">
            <span class="title">故障处理结果:</span>
            <div class="content">{{model.repairResult}}</div>
          </td>
        </tr>
        <tr class="tbtr" style="height: 100px">
          <td
            class="tbtd"
            colspan="4"
            align="left"
            valign="top"
            height="240px">
            <span class="title">更换备件清单:</span>
            <div v-for="(item,index) in model.fittingReplacedList" :key="index" class="content"><span>名称:{{item.name}},型号:{{item.specModel}},数量:{{item.quantity}}</span></div>
          </td>
        </tr>
        <tr class="tbtr">
          <td class="tbtd" colspan="2" align="left" valign="middle"><span class="title">维修员(签字):</span> <span v-if="model.logVoList"><img v-for="(item,index) in model.logVoList" :key="index" style="width: 80px;height: 80px" :src="ip+item.signature"/></span><span v-else></span></td>
          <td class="tbtd" colspan="2" align="left" valign="center"><span class="title">使用单位(签字):</span><img v-if="model.usingCompanySignature" style="width: 80px;height: 80px" :src="ip+model.usingCompanySignature"/><span v-else></span></td>
        </tr>
      </table>
    </div>
  </el-dialog>
</template>

<script>
  import print from "print-js";
  // import html2canvas from "html2canvas";
  export default {
    name: "RepairPrint",
    data() {
      return {id:"",dialogVisible:false,model:{logVoList:[]},ip:window.config.pictureUrl,print:true};
    },
    methods:{
      addWatermark(content) {
        this.$watermark.set(content,this.$refs.print);
      },
      printSomething() {
        console.log(window.devicePixelRatio * 441,68);
        // html2canvas(this.$refs.print, {
        //   logging: false,
        //   dpi: window.devicePixelRatio * 300, // 将分辨率提高到特定的DPI 提高四倍
        //   scale: 1, // 按比例增加分辨率，调整显示比例
        //   allowTaint: true,
        //   useCORS: true,
        //   background: "#ffffff",
        // }).then(canvas => {
        //   print({
        //     printable: [canvas.toDataURL()],// 图片路径，支持多张图片
        //     type: "image",
        //     style: "@page{size:auto;margin: 130px 30px 0px 30px;}",//去除页眉页脚
        //   });
        // });
        this.print = true;
        const table = document.querySelector(".tb");
        table.style.margin = "0 0";
        print({
          printable:"print",
          type:"html",
          // targetStyle: ["* "],
          targetStyles:["*"],
          onPrintDialogClose:()=>{

          }});
        table.style.margin="20px auto";

      },
      open(id) {
        console.log(id,81);
        this.id = id;

        this.dialogVisible = true;
        this.getData();
        // this.getRepairOrder();
      },
      getData() {
        if (this.id) {
          this.contentLoading = true;
          this.$http.get(`maintenance/repair-order/${this.id}/print-preview`).then(data => {
            this.contentLoading = false;
            this.model = data;
            const arr = [];
            if (data.logVoList instanceof Array) {
              data.logVoList.forEach(item=>{
                arr.push(item.operatorName);
              });
            }
            this.addWatermark(arr.join(" ") +" "+ data.completedTime);
            // const arr =[{content:data}];

          });
        }
      },
    },
  };
</script>

<style scoped>
.tb{
  margin: 20px auto;
}
.tbtr{
  border: 1px solid black;

}
.tbtd{
  /*width: 400px;*/
  padding-left: 10px;
border-right: 1px solid black

}
.title{
  font-size: 24px;
  line-height: 30px;
}
.content{
  color: black;
  font-size: 24px;
  line-height: 30px;
  margin-left: 10px;
}
</style>
